export const environment = {
  production: true,
  version: '1.5.1',
  revisionId: 'g75b4fe1',
  firebaseConfig: {
    apiKey: 'AIzaSyBGF2QUP_7ihYUcdJeK2vnlKvS5ehqikys',
    authDomain: 'whisky-bazar.firebaseapp.com',
    databaseURL: 'https://whisky-bazar.firebaseio.com',
    projectId: 'whisky-bazar',
    storageBucket: 'whisky-bazar.appspot.com',
    messagingSenderId: '275024405635',
    emulators: null,
  },
  cloudFunctions: 'https://us-central1-whisky-bazar.cloudfunctions.net',
  googleTagManager: {
    containerID: 'GTM-T97GHQV',
  },
  meilisearchConfig: {
    host: 'https://search.whiskybazar.com',
    apiKey: '6cf20e13749e269373215d48d2d611b1f7bffa26f63ef4a0fa6e9090848cfdfc',
    indexes: {
      openAuctions: 'open-auctions-v1',
    },
  },
};
